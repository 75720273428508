<template>
  <div>
    <el-row :gutter="15">
      <el-col :span="12" :xs="24">
        <h3>1.公司基本信息</h3>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="90px"
          v-loading="loading"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="公司名称" prop="companyName">
                <el-input v-model="form.companyName" :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="所在展位" prop="boothId">
                <el-tag closable v-show="form.boothName" @close="onBoothTag">
                  {{ form.boothName }}
                </el-tag>
                <el-cascader
                  v-show="!form.boothName"
                  :props="propsBooth"
                  @change="handleBooth"
                  placeholder="请选择所在展位，可按名称搜索"
                  style="width: 100%"
                  filterable
                  clearable
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="公司所在地" prop="areaId">
                <el-tag closable v-show="form.areaName" @close="onAreaTag">
                  {{ form.areaName }}
                </el-tag>
                <el-cascader
                  v-show="!form.areaName"
                  :props="propsArea"
                  @change="handleArea"
                  style="width: 100%"
                ></el-cascader>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="公司简介" prop="introduction">
                <el-input
                  type="textarea"
                  :rows="3"
                  resize="none"
                  v-model="form.introduction"
                  placeholder="请输入公司简介"
                  maxlength="500"
                  show-word-limit
                />
              </el-form-item>
            </el-col>

            <el-col :span="12" :xs="24">
              <el-form-item label="联系人" prop="linkMan">
                <el-input
                  v-model="form.linkMan"
                  placeholder="请输入联系人"
                  maxlength="20"
                  show-word-limit
                />
              </el-form-item>
            </el-col>

            <el-col :span="12" :xs="24">
              <el-form-item label="联系电话" prop="linkTel">
                <el-input
                  v-model="form.linkTel"
                  placeholder="请输入联系电话"
                  maxlength="20"
                  show-word-limit
                />
              </el-form-item>
            </el-col>

            <el-col :span="12" :xs="24">
              <el-form-item label="联系邮箱" prop="linkMail">
                <el-input
                  v-model="form.linkMail"
                  placeholder="请输入联系邮箱"
                  maxlength="20"
                  show-word-limit
                />
              </el-form-item>
            </el-col>

            <el-col :span="12" :xs="24">
              <el-form-item label="邮编" prop="postCode">
                <el-input
                  v-model="form.postCode"
                  placeholder="请输入邮编"
                  maxlength="20"
                  show-word-limit
                />
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="地址" prop="address">
                <el-input
                  v-model="form.address"
                  placeholder="请输入地址"
                  maxlength="200"
                  type="textarea"
                  show-word-limit
                />
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="官网" prop="officialSite">
                <el-input
                  v-model="form.officialSite"
                  placeholder="请输入官网"
                  maxlength="100"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
          </el-row>
          <span>
            <el-button type="primary" @click="submit">保存基本信息</el-button>
          </span>
        </el-form>
      </el-col>

      <el-col :span="12" :xs="24">
        <el-card shadow="hover">
          <div slot="header">
            2.上传附件 <b v-show="!uniqueId">请保存公司基本信息</b>
          </div>
          <AttachVod
            ref="AttachVodCover"
            :maxCount="1"
            btnTitle="请您上传宣传的封面"
            accept="image/jpeg,image/png,image/bmp,image/gif"
          >
            <template v-slot:tip>
              您只能上传<b>一个</b>宣传的封面（图片）
            </template>
          </AttachVod>

          <AttachVod
            ref="AttachVodAd"
            :maxCount="1"
            btnTitle="请上传公司宣传展示视频"
            accept="audio/*, video/*"
          >
            <template v-slot:tip> 您只能上传<b>一个</b>宣传的视频 </template>
          </AttachVod>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getCompany, updateCompany } from "@/api/company/company.js";
import AttachVod from "@/components/AttachVod/Index.vue";
import { listBooth } from "@/api/booth/booth.js";
import { listAreaByParent } from "@/api/cms/area.js";

export default {
  data() {
    return {
      visible: false,
      loading: false,
      uniqueId: "",
      form: {},
      // 表单校验
      rules: {
        companyName: [
          { required: true, message: "请填写企业名称", trigger: "blur" },
        ],
        introduction: [
          { required: true, message: "请输入公司简介", trigger: "blur" },
        ],
        linkMan: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        linkTel: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
      },
      propsArea: {
        lazy: true,
        lazyLoad: this.lazyLoadArea,
      },
      propsBooth: {
        lazy: true,
        lazyLoad: this.lazyLoadBooth,
        multiple: true,
        checkStrictly: true,
      },
    };
  },
  components: {
    AttachVod,
  },
  methods: {
    getCompany() {
      this.visible = true;
      this.loading = true;
      getCompany().then((res) => {
        this.form = res;
        this.loading = false;

        this.uniqueId = res.uniqueId;
        this.refreshAttach();
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.doSave();
        } else {
          return false;
        }
      });
    },
    doSave() {
      this.loading = true;
      updateCompany(this.form)
        .then((res) => {
          this.uniqueId = res;
          this.refreshAttach();
          this.msgSuccess();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refreshAttach() {
      this.$refs.AttachVodCover.show("Company", this.uniqueId, "Cover");
      this.$refs.AttachVodAd.show("Company", this.uniqueId, "Ad");
    },
    lazyLoadArea(node, resolve) {
      var pid = node.value;
      if (pid == null) {
        pid = 0;
      }
      listAreaByParent(pid).then((res) => {
        const nodes = res.map((val) => ({
          value: val.id,
          label: val.name,
          leaf: node.level > 0,
        }));
        resolve(nodes);
      });
    },
    lazyLoadBooth(node, resolve) {
      var pid = node.value;
      if (pid == null) {
        pid = 0;
      }
      listBooth(pid).then((res) => {
        const nodes = res.map((val) => ({
          value: val.id,
          label: val.name,
          leaf: node.level > 0,
        }));
        resolve(nodes);
      });
    },
    handleArea(value) {
      this.form.areaId = value[1];
    },
    onAreaTag() {
      this.form.areaName = null;
      this.form.areaId = 0;
    },
    handleBooth(value) {
      let ids = [];
      value.forEach((item) => {
        ids.push(item[item.length - 1]);
      });
      this.form.boothIds = ids;
    },
    onBoothTag() {
      this.form.boothName = null;
      this.form.boothIds = [];
    },
  },
  created() {
    this.getCompany();
  },
};
</script>

<style></style>
